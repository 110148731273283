<template>
    <div class="table-wrap">
      <h1>基本信息</h1>
      <table class="table-box" border="1" cellspacing="0">
        <thead>
          <tr align="center">
            <td colspan="6" ><b>受检者信息</b></td>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>姓名</td>
          <td>{{ info.section1.patientName }}</td>
          <td>性别</td>
          <td>{{ info.section1.sex }}</td>
          <td>年龄</td>
          <td>{{ info.section1.age }}</td>
        </tr>
        <tr>
          <td>医疗机构</td>
          <td colspan="2">{{ info.section1.hospital }}</td>
          <td>科室</td>
          <td colspan="2">{{ info.section1.office }}</td>
          </tr>
        </tbody>
        <thead>
        <tr align="center">
          <td colspan="6" ><b>临床信息</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>肿瘤类型</td>
          <td colspan="5"><span>{{info.section1.tumorTypeCheckA === 'R'?'☑':'□'}}</span>原发 <span>{{info.section1.tumorTypeCheckB === 'R'?'☑':'□'}}</span>复发 <span>{{info.section1.tumorTypeCheckC === 'R'?'☑':'□'}}</span>转移   <u>{{info.section1.tumorTypeCheckC === 'R'?info.section1.tumorTypeCheckCInfo:''}}</u></td>
        </tr>
        <tr>
          <td>临床诊断</td>
          <td colspan="5">{{ info.section1.clinicalDiagnosis }}</td>
        </tr>
        <tr>
          <td>病例诊断</td>
          <td colspan="5">{{ info.section1.pathologicDiagnosis }}</td>
        </tr>
        <tr>
          <td>既往治疗</td>
          <td colspan="5">
            <span>{{info.section1.preTreatCheckA === 'R'?'☑':'□'}}</span>放疗
            <span>{{info.section1.preTreatCheckB === 'R'?'☑':'□'}}</span>手术
            <u>{{info.section1.preTreatCheckB === 'R'?info.section1.preTreatCheckBInfo:''}}</u>
            <span>{{info.section1.preTreatCheckC === 'R'?'☑':'□'}}</span>化疗
            <u>{{info.section1.preTreatCheckC === 'R'?info.section1.preTreatCheckCInfo:''}}</u>
            <span>{{info.section1.preTreatCheckD === 'R'?'☑':'□'}}</span>靶向治疗
            <u>{{info.section1.preTreatCheckD === 'R'?info.section1.preTreatCheckDInfo:''}}</u>
            <span>{{info.section1.preTreatCheckE === 'R'?'☑':'□'}}</span>其他
            <u>{{info.section1.preTreatCheckE === 'R'?info.section1.preTreatCheckEInfo:''}}</u>
          </td>
        </tr>
        </tbody>
        <thead>
        <tr align="center">
          <td colspan="6" ><b>样本信息</b></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>样本编号</td>
          <td colspan="2">{{ info.section1.sampleNo }}</td>
          <td>取样时间</td>
          <td colspan="2">{{ info.section1.takeTime }}</td>
        </tr>
        <tr>
          <td>样本重量/体积</td>
          <td colspan="2">{{ info.section1.weightVol }}</td>
          <td>取样部位</td>
          <td colspan="2">{{ info.section1.specimenLocation }}</td>
        </tr>
        <tr>
          <td rowspan="2">取样方式</td>
          <td>组织样本</td>
          <td colspan="4">
            <span>{{info.section1.tissueSampleCheckA === 'R'?'☑':'□'}}</span>外科手术

            <span>{{info.section1.tissueSampleCheckB === 'R'?'☑':'□'}}</span>穿刺活检

            <span>{{info.section1.tissueSampleCheckC === 'R'?'☑':'□'}}</span>内镜活检

          </td>
        </tr>
        <tr>
          <td>恶性积液</td>
          <td colspan="4">
            <span>{{info.section1.effusionCheckA === 'R'?'☑':'□'}}</span>胸腔

            <span>{{info.section1.effusionCheckB === 'R'?'☑':'□'}}</span>腹腔

            <span>{{info.section1.effusionCheckC === 'R'?'☑':'□'}}</span>其他

            <u>{{info.section1.effusionCheckC === 'R'?info.section1.effusionCheckCInfo:''}}</u>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="table-tips">
        附注：以上信息均为送检时临床医生或受检样本来源者提供信息，本报告不对上述内容进行判断或解读。
      </div>
      <h1>药敏分析结果</h1>
      <table class="table-box" border="1" cellspacing="0">
        <thead>
        <tr align="center">
          <td colspan="6" >药敏方案确认表</td>
        </tr>
        </thead>
        <tbody>
        <tr align="center">
          <td>用药方案</td>
          <td colspan="2" style="width: 50%">药品编号及通用名</td>
          <td>肿瘤抑制率</td>
        </tr>
        <tr align="center" v-for="(item,index) in info.section2.drugTable" :key="index">
          <td>{{ item.drugPlan }}</td>
          <td>{{ index + 1 }}</td>
          <td>{{ item.commonName }}</td>
          <td align="left">{{item.killAbility}}%</td>
        </tr>
        </tbody>
      </table>
      <h1>结果分析与解读</h1>

      <div class="chartList">
        <div id="line1" class="chart"></div>
<!--        <div v-for="(item,index) in info.section3" :key="index" :id="'line' + index" class="chart"></div>-->
      </div>
      <div class="table-tips">
        附注<br>
        1.肿瘤抑制率：通过给药孔和对照孔的细胞ATP含量计算所得。肿瘤抑制率数值越大，表示药物对肿瘤细胞生长抑制的效果越强。<br>
        2.该结果基于患者类器官模型的药物生理浓度计算得到，未考虑药物不良反应、药物相互作用等风险，仅供临床参考使用。
      </div>
      <h1>结果分析与解读</h1>
      <div class="table-tips">
        所测试的药物方案中，根据肿瘤抑制率由大到小排序，排序前三名的药物方案依次是：{{info.section3.drug1}}、{{info.section3.drug2}}、{{info.section3.drug3}}。<br>
        涉及的药物信息介绍如下：
      </div>
      <table class="table-box" border="1" cellspacing="0">
        <thead>
        <tr align="center">
          <td>药品名称</td>
          <td>适应症</td>
        </tr>
        </thead>
        <tbody>
        <tr align="center" v-for="(item,index) in info.section3.drugList" :key="index">
          <td>{{ item.name }}</td>
          <td align="left">{{ item.indication }}</td>
        </tr>
        <tr align="center" v-if="!info.section3.drugList.length">
          <td>暂无</td>
          <td>暂无</td>
        </tr>
        </tbody>
      </table>
      <h1>{{info.section3.growDesc}}肿瘤抑制率</h1>
      <table class="table-box" border="1" cellspacing="0">
        <thead>
        <tr align="center">
          <td></td>
          <td>Day1</td>
          <td>Day3</td>
          <td>Day5</td>
        </tr>
        </thead>
        <tbody>
        <tr align="center">
          <td>阴性对照</td>
          <td><img v-if="info.section3.r1p1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r1p1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.r1p2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r1p2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.r1p3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r1p3" /><span v-else>暂无照片</span></td>
        </tr>
        <tr align="center">
          <td>Cmax{{info.section3.drug1}}</td>
          <td><img v-if="info.section3.r2p1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r2p1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.r2p2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r2p2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.r2p3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r2p3" /><span v-else>暂无照片</span></td>
        </tr>
        <tr align="center">
          <td>Cmax{{info.section3.drug2}}</td>
          <td><img v-if="info.section3.r3p1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r3p1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.r3p2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r3p2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.r3p3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r3p3" /><span v-else>暂无照片</span></td>
        </tr>
        <tr align="center">
          <td>Cmax{{info.section3.drug3}}</td>
          <td><img v-if="info.section3.r4p1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r4p1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.r4p2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r4p2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.r4p3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.r4p3" /><span v-else>暂无照片</span></td>
        </tr>
        </tbody>
      </table>
      <div class="table-tips">
        注：结果分析与解读部分仅展示肿瘤抑制率排序前三名的药物组合
      </div>

      <h1>样本收取与质量控制</h1>
      <table class="table-box" style="margin-top: 20px" border="1" cellspacing="0">
        <tbody>
        <tr align="center">
          <td>样本编号</td>
          <td colspan="2">{{info.section4.sampleNo}}</td>
          <td>样本离体时间</td>
          <td colspan="2">{{info.section4.leaveTime}}</td>
        </tr>
        <tr align="center">
          <td>样本接收时间</td>
          <td colspan="2">{{info.section4.receiveTime}}</td>
          <td>样本处理时间</td>
          <td colspan="2">{{info.section4.handleTime}}</td>
        </tr>
        <tr align="center">
          <td rowspan="2">样本状态照片</td>
          <td colspan="3"><img v-if="info.section4.sampleStatusImg1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.sampleStatusImg1" /><span v-else>暂无照片</span></td>

          <td colspan="2"><img v-if="info.section4.sampleStatusImg2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.sampleStatusImg2" /><span v-else>暂无照片</span></td>
        </tr>
        <tr align="center">
          <td colspan="3">(样本在计量秤上照片)</td>

          <td colspan="2">(培养皿内样本特写)</td>
        </tr>
        <tr align="center">
          <td colspan="6">样本质量评分</td>
        </tr>
        <tr align="center">
          <td>项目</td>
          <td colspan="2">变量</td>
          <td>分类</td>
          <td>评分标准</td>
          <td>评分</td>
        </tr>
        <tr align="center">
          <td rowspan="12">样本信息</td>
          <td rowspan="3" colspan="2">样本离体时间</td>
          <td>＜24小时</td>
          <td>15</td>
          <td>{{info.section4.leaveTime1}}</td>
        </tr>
        <tr align="center">
          <td>24-48小时</td>
          <td>10</td>
          <td>{{info.section4.leaveTime2}}</td>
        </tr>
        <tr align="center">
          <td>＞48小时</td>
          <td>5</td>
          <td>{{info.section4.leaveTime3}}</td>
        </tr>
        <tr align="center">
          <td rowspan="3" colspan="2">样本重量</td>
          <td>＞1g</td>
          <td>15</td>
          <td>{{info.section4.weightLevel1}}</td>
        </tr>
        <tr align="center">
          <td>0.2g-1g</td>
          <td>10</td>
          <td>{{info.section4.weightLevel2}}</td>
        </tr>
        <tr align="center">
          <td>＜0.2g</td>
          <td>5</td>
          <td>{{info.section4.weightLevel3}}</td>
        </tr>
        <tr align="center">
          <td rowspan="6" colspan="2">样本中细胞占比</td>
          <td>>90%</td>
          <td>35</td>
          <td>{{info.section4.cellProportion1}}</td>
        </tr>
        <tr align="center">
          <td>70%-90%</td>
          <td>25</td>
          <td>{{info.section4.cellProportion2}}</td>
        </tr>
        <tr align="center">
          <td>50%-70%</td>
          <td>20</td>
          <td>{{info.section4.cellProportion3}}</td>
        </tr>
        <tr align="center">
          <td>30%-50%</td>
          <td>15</td>
          <td>{{info.section4.cellProportion4}}</td>
        </tr>
        <tr align="center">
          <td>5%-30%</td>
          <td>10</td>
          <td>{{info.section4.cellProportion5}}</td>
        </tr>
        <tr align="center">
          <td>＜5%</td>
          <td>0</td>
          <td>{{info.section4.cellProportion6}}</td>
        </tr>
        </tbody>

        <tbody v-if="info.section1.cancerType == '滑膜肉瘤'">
        <tr align="center">
          <td rowspan="6">增殖能力</td>
          <td rowspan="3" colspan="2">模型构建前培养天数</td>
          <td>≤7天</td>
          <td>15</td>
          <td>{{info.section4.organFormDays1}}</td>
        </tr>
        <tr align="center">
          <td>7-14天</td>
          <td>8</td>
          <td>{{info.section4.organFormDays2}}</td>
        </tr>
        <tr align="center">
          <td>＞5天</td>
          <td>3</td>
          <td>{{info.section4.organFormDays3}}</td>
        </tr>
        <tr align="center">
          <td rowspan="3" colspan="2">增殖速率</td>
          <td>＞14天</td>
          <td>20</td>
          <td>{{info.section4.prolfRate1}}</td>
        </tr>
        <tr align="center">
          <td>适中</td>
          <td>10</td>
          <td>{{info.section4.prolfRate2}}</td>
        </tr>
        <tr align="center">
          <td>较慢</td>
          <td>5</td>
          <td>{{info.section4.prolfRate3}}</td>
        </tr>
        </tbody>
        <tbody v-else-if="info.section1.cancerType == '骨肉瘤'">

        <tr align="center">
          <td rowspan="6">增殖能力</td>
          <td rowspan="3" colspan="2">可传代所需天数</td>
          <td>≤3天</td>
          <td>15</td>
          <td>{{info.section4.organFormDays1}}</td>
        </tr>
        <tr align="center">
          <td>4-7天</td>
          <td>8</td>
          <td>{{info.section4.organFormDays2}}</td>
        </tr>
        <tr align="center">
          <td>＞＞7天</td>
          <td>3</td>
          <td>{{info.section4.organFormDays3}}</td>
        </tr>
        <tr align="center">
          <td rowspan="3" colspan="2">增殖速率</td>
          <td>＞14天</td>
          <td>20</td>
          <td>{{info.section4.prolfRate1}}</td>
        </tr>
        <tr align="center">
          <td>适中</td>
          <td>10</td>
          <td>{{info.section4.prolfRate2}}</td>
        </tr>
        <tr align="center">
          <td>较慢</td>
          <td>5</td>
          <td>{{info.section4.prolfRate3}}</td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr align="center">
          <td rowspan="6">增殖能力</td>
          <td rowspan="3" colspan="2">形成类器官天数</td>
          <td>≤2天</td>
          <td>15</td>
          <td>{{info.section4.organFormDays1}}</td>
        </tr>
        <tr align="center">
          <td>3-5天</td>
          <td>8</td>
          <td>{{info.section4.organFormDays2}}</td>
        </tr>
        <tr align="center">
          <td>＞5天</td>
          <td>3</td>
          <td>{{info.section4.organFormDays3}}</td>
        </tr>
        <tr align="center">
          <td rowspan="3" colspan="2">增殖速率</td>
          <td>＞较快</td>
          <td>20</td>
          <td>{{info.section4.prolfRate1}}</td>
        </tr>
        <tr align="center">
          <td>适中</td>
          <td>10</td>
          <td>{{info.section4.prolfRate2}}</td>
        </tr>
        <tr align="center">
          <td>较慢</td>
          <td>5</td>
          <td>{{info.section4.prolfRate3}}</td>
        </tr>
        </tbody>
        <tr align="center">
          <td rowspan="4">其他</td>
          <td colspan="2">污染情况</td>
          <td>培养2-5天内污染</td>
          <td>0</td>
          <td>{{info.section4.polLevel1}}</td>
        </tr>
        <tr align="center">
          <td rowspan="2" colspan="2">样本到达温度</td>
          <td>8-20℃</td>
          <td>-5</td>
          <td>{{info.section4.temperaLevel1}}</td>
        </tr>
        <tr align="center">
          <td>>20℃</td>
          <td>-10</td>
          <td>{{info.section4.temperaLevel2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">保存液有效期</td>
          <td>已过期</td>
          <td>-10</td>
          <td>{{info.section4.fluidExpiration1}}</td>
        </tr>
        <tr align="center">
          <td colspan="5">样本综合评分</td>
          <td>{{info.section4.smpTotalScore}}</td>
        </tr>

      </table>
      <div class="table-tips">
        附注<br>
        1. 以上评分基于统计学多因素分析，分值设定以各变量权重为依据。<br>
        2. 一般情况下，当“样本中组织细胞占比”以及“污染情况”两项中有一项得分为0时，将不对样本做进一步处理。<br>
        3. 当样本综合得分＞45分时，本机构承诺单项药敏分析服务将在收到样本后3周内完成。<br>
        4. 样本应按要求规范建议保存于组织保存液中，并于2-8℃低温环境条件下运输至实验室进行处理。<br>
      </div>
      <h1><span v-if="info.section1.cancerType == '骨肉瘤'">肿瘤细胞培养</span><span v-else>肿瘤类器官原代培养</span>形态图（任选2孔）</h1>
      <table class="table-box" border="1" cellspacing="0">
        <tbody>
        <tr align="center">
          <td><img v-if="info.section4.yk1p1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.yk1p1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section4.yk1p2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.yk1p2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section4.yk1p3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.yk1p3" /><span v-else>暂无照片</span></td>
        </tr>
        <tr align="center">
          <td>铺板</td>
          <td>{{info.section4.yk1s2}}</td>
          <td>{{info.section4.yk1s3}}</td>
        </tr>
        </tbody>
      </table>
      <table class="table-box" style="margin-top: 20px" border="1" cellspacing="0">
        <tbody>
        <tr align="center">
          <td><img v-if="info.section4.yk2p1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.yk2p1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section4.yk2p2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.yk2p2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section4.yk2p3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.yk2p3" /><span v-else>暂无照片</span></td>
        </tr>
        <tr align="center">
          <td>铺板</td>
          <td>{{info.section4.yk1s2}}</td>
          <td>{{info.section4.yk1s3}}</td>
        </tr>
        </tbody>
      </table>
      <h1>肿瘤类器官传代培养（任选2孔）</h1>
      <table class="table-box" border="1" cellspacing="0">
        <tbody>
        <tr align="center">
          <td><img v-if="info.section3.ck1p1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.ck1p1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.ck1p2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.ck1p2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section3.ck1p3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section3.ck1p3" /><span v-else>暂无照片</span></td>
        </tr>
        <tr align="center">
          <td>铺板</td>
          <td>{{info.section4.ck1s2}}</td>
          <td>{{info.section4.ck1s3}}</td>
        </tr>
        </tbody>
      </table>
      <table class="table-box" style="margin-top: 20px" border="1" cellspacing="0">
        <tbody>

        <tr align="center">
          <td><img v-if="info.section4.ck2p1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.ck2p1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section4.ck2p2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.ck2p2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section4.ck2p3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.ck2p3" /><span v-else>暂无照片</span></td>
        </tr>
        <tr align="center">
          <td>铺板</td>
          <td>{{info.section4.ck1s2}}</td>
          <td>{{info.section4.ck1s3}}</td>
        </tr>
        </tbody>
      </table>
      <h1>类器官建模型质量控制</h1>

      <table class="table-box" style="margin-top: 20px" border="1" cellspacing="0">
        <tbody>
        <tr align="center">
          <td rowspan="2">类器官建模照片</td>
          <td colspan="2"><img v-if="info.section4.picModel1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.picModel1" /><span v-else>暂无照片</span></td>

          <td><img v-if="info.section4.picModel2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.picModel2" /><span v-else>暂无照片</span></td>
        </tr>

        <tr align="center">
          <td colspan="2"><span v-if="info.section1.cancerType == '骨肉瘤'">药敏前3D肿瘤球明场照片</span><span v-else>药敏前类器官明场照片</span></td>
          <td><span v-if="info.section1.cancerType == '骨肉瘤'">3D肿瘤球特写图</span><span v-else>类器官特写图</span></td>
        </tr>
        </tbody>
        </table>
      <table class="table-box" style="margin-top: 20px" border="1" cellspacing="0">
        <tr align="center">
          <td v-if="info.section1.cancerType == '骨肉瘤'" colspan="5">肿瘤球模型质量评分</td>
          <td v-else colspan="5">类器官模型质量评分</td>
        </tr>
        <tr align="center">
          <td>变量</td>
          <td colspan="2">分类</td>
          <td>评分标准</td>
          <td>评分</td>
        </tr>
        <tr align="center">
          <td rowspan="5">样本质控评分</td>
          <td colspan="2">＞90</td>
          <td>20</td>
          <td>{{info.section4.qualityCon1}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">80-90</td>
          <td>15</td>
          <td>{{info.section4.qualityCon2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＞60-79</td>
          <td>10</td>
          <td>{{info.section4.qualityCon3}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＞41-59</td>
          <td>5</td>
          <td>{{info.section4.qualityCon4}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">≤40</td>
          <td>0</td>
          <td>{{info.section4.qualityCon5}}</td>
        </tr>
        <tbody v-if="info.section1.cancerType == '滑膜肉瘤'">
        <tr align="center">
          <td rowspan="4">模型构建天数</td>
          <td colspan="2">＜7天</td>
          <td>25</td>
          <td>{{info.section4.cultiDays1}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">7-14天</td>
          <td>20</td>
          <td>{{info.section4.cultiDays2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">15-21天</td>
          <td>10</td>
          <td>{{info.section4.cultiDays3}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＞21天</td>
          <td>5</td>
          <td>{{info.section4.cultiDays4}}</td>
        </tr>
        <tr align="center">
          <td rowspan="4">细胞数量</td>
          <td colspan="2">＞5*10<sup>6</sup></td>
          <td>30</td>
          <td>{{info.section4.organoidNum1}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">1*10<sup>6</sup>-5*10<sup>6</sup></td>
          <td>20</td>
          <td>{{info.section4.organoidNum2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">2*10<sup>5</sup>-1*10<sup>6</sup></td>
          <td>15</td>
          <td>{{info.section4.organoidNum3}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＜2*10<sup>5</sup></td>
          <td>15</td>
          <td>{{info.section4.organoidNum4}}</td>
        </tr>
        </tbody>
        <tbody v-else-if="info.section1.cancerType == '骨肉瘤'">
        <tr align="center">
          <td rowspan="4">总培养天数</td>
          <td colspan="2">＜7天</td>
          <td>25</td>
          <td>{{info.section4.cultiDays1}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">7-14天</td>
          <td>20</td>
          <td>{{info.section4.cultiDays2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">15-21天</td>
          <td>10</td>
          <td>{{info.section4.cultiDays3}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＞21天</td>
          <td>5</td>
          <td>{{info.section4.cultiDays4}}</td>
        </tr>
        <tr align="center">
          <td rowspan="4">肿瘤细胞数量</td>
          <td colspan="2">＞5*10<sup>6</sup></td>
          <td>30</td>
          <td>{{info.section4.organoidNum1}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">1*10<sup>6</sup>-5*10<sup>6</sup></td>
          <td>20</td>
          <td>{{info.section4.organoidNum2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">2*10<sup>5</sup>-1*10<sup>6</sup></td>
          <td>15</td>
          <td>{{info.section4.organoidNum3}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＜2*10<sup>5</sup></td>
          <td>15</td>
          <td>{{info.section4.organoidNum4}}</td>
        </tr>
        </tbody>

        <tbody v-else>
        <tr align="center">
          <td rowspan="4">总培养天数</td>
          <td colspan="2">＜7天</td>
          <td>25</td>
          <td>{{info.section4.cultiDays1}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">7-14天</td>
          <td>20</td>
          <td>{{info.section4.cultiDays2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">15-21天</td>
          <td>10</td>
          <td>{{info.section4.cultiDays3}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＞21天</td>
          <td>5</td>
          <td>{{info.section4.cultiDays4}}</td>
        </tr>
        <tr align="center">
          <td rowspan="4">类器官数量</td>
          <td colspan="2">＞5000</td>
          <td>30</td>
          <td>{{info.section4.organoidNum1}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">3001-5000</td>
          <td>20</td>
          <td>{{info.section4.organoidNum2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">1000-3000</td>
          <td>15</td>
          <td>{{info.section4.organoidNum3}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＜1000</td>
          <td>15</td>
          <td>{{info.section4.organoidNum4}}</td>
        </tr>
        </tbody>
        <tr align="center">
          <td rowspan="4">消化后细胞活率</td>
          <td colspan="2">≥80%</td>
          <td>25</td>
          <td>{{info.section4.pdgCellRate1}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">50%-79%</td>
          <td>15</td>
          <td>{{info.section4.pdgCellRate2}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">10%-49%</td>
          <td>10</td>
          <td>{{info.section4.pdgCellRate3}}</td>
        </tr>
        <tr align="center">
          <td colspan="2">＜10%</td>
          <td>0</td>
          <td>{{info.section4.pdgCellRate4}}</td>
        </tr>

        <tr align="center">
          <td colspan="4">类器官综合总分</td>
          <td>{{info.section4.orgScore}}</td>
        </tr>

      </table>
      <div class="table-tips">
        附注<br>
        以上评分基于统计学多因素分析，分值设定以各变量权重为依据。一般情况下，评分低于40分时，类器官培养的时间需要延长，药敏分析进度相应延长。
      </div>
      <h1>药物敏感性分析</h1>
      <table class="table-box" style="margin-top: 20px" border="1" cellspacing="0">
        <tbody>
        <tr align="center">
          <td></td>
          <td>Day0</td>
          <td>Day1</td>
          <td>Day2</td>
        </tr>
        <tr align="center">
          <td>name</td>

          <td><img v-if="info.section4.pic1" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.pic1" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section4.pic2" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.pic2" /><span v-else>暂无照片</span></td>
          <td><img v-if="info.section4.pic3" style="width:200px; height: auto;margin: auto;display: block;" :src="info.section4.pic3" /><span v-else>暂无照片</span></td>

        </tr>
        </tbody>
      </table>
      <div class="btn-wrap">
        <el-button type="primary" @click="getReport" :loading="loading">生成报告</el-button>
      </div>
    </div>
</template>

<script>
import { getDrugReport, saveReport } from '@/api/exp/drugtest'
import { baseUrl } from '@/config/index'
import mixin from '@/util/mixin'
export default {
  mixins: [mixin],
  data () {
    return {
      loading: false,
      baseUrl,
      info: {
        section1: {},
        section2: {},
        section3: {},
        section4: {},
        section5: {},
        section6: {},
        section7: {}
      },
      experimentId: '',
      myChart: [],
      chartImg: [],
      option: {
        title: {
          text: '肿瘤抑制率 %',
          left: 'center'
        },
        backgroundColor: '#ffffff',
        xAxis: [{
          name: '',
          nameGap: 24,
          nameLocation: 'middle',
          type: 'value',
          max: 110,
          min: 0,
          splitNumber: 10,
          axisLine: { onZero: false },
          alignTicks: true,
          axisLabel: {
            formatter: function (value) {
              return value - 10
            }
          }
        }, {
          nameLocation: 'middle',
          type: 'category',
          splitNumber: 20,
          alignTicks: true,
          axisTick: {
            inside: true,
            length: 20
          },
          offset: 50,
          position: 'bottom',
          axisLabel: {
            align: 'left',
            interval: (index, value) => {
              switch (index) {
                case 0:
                  return 1
                case 3:
                  return 1
                case 5:
                  return 1
                case 7:
                  return 1
                case 9:
                  return 1
                default:
                  return 0
              }
            }
          },
          data: ['不敏感', '', '', '敏感性较低', '', '一般敏感', '', '较敏感', '', '敏感', '']
        }],
        yAxis: {
          type: 'category',
          axisLine: { onZero: false },
          name: ''
        },
        grid: {
          left: '5%',
          right: '10%',
          top: 50,
          containLabel: true
        },
        series: [
          {
            name: '',
            type: 'bar',
            label: {
              show: true,
              position: 'right',
              formatter: (prama) => {
                return `${prama.value - 10}%`
              }
            }
          }
        ]
      }
    }
  },
  computed: {

  },
  created () {
    this.experimentId = this.$route.query.experimentId
    this.getData()
  },
  mounted () {
  },
  methods: {
    getReport () {
      this.chartImg = []
      for (let i = 0; i < this.myChart.length; i++) {
        this.chartImg.push(this.myChart[i].getDataURL())
      }
      this.loading = true
      this.$confirm('是否下载当前报告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        saveReport({
          pics: this.chartImg,
          experimentId: this.experimentId
        }).then(res => {
          window.open(res.data)
          this.loading = false
          // downloadFile(res, this.info.section1.patient + '_肿瘤类器官药敏检测服务分析报告.docx')
        })
      }).catch(() => {

      })

      // function downloadFile (data, fileName) {
      //   const blob = new Blob([data], { type: `application/vnd.openxmlformats-officedocument.wordprocessingml.document` })
      //   // 获取heads中的filename文件名
      //   const downloadElement = document.createElement('a')
      //   // 创建下载的链接
      //   const href = window.URL.createObjectURL(blob)
      //   downloadElement.href = href
      //   // 下载后文件名
      //   downloadElement.download = fileName
      //   document.body.appendChild(downloadElement)
      //   // 点击下载
      //   downloadElement.click()
      //   // 下载完成移除元素
      //   document.body.removeChild(downloadElement)
      //   // 释放掉blob对象
      //   window.URL.revokeObjectURL(href)
      // }
    },
    getChart (obj) {
      // const colorArr = ['red', 'green', 'fuchsia', 'blue', 'aqua', 'navy', 'olive', 'purple', 'lime']
      this.myChart = []
      this.$nextTick(() => {
        const myChart = this.$echarts.init(document.getElementById('line1'))
        const listener = function () {
          myChart.resize()
        }
        this.option.yAxis.data = unshiftArray(obj.axisDrugName)
        this.option.series[0].data = unshiftArray(obj.axisKill.map((item, index) => {
          const _item = {}
          _item.value = item + 10
          if (index < 3) {
            _item.itemStyle = {
              color: '#fd9600'
            }
          }
          return _item
        }))
        function unshiftArray (arr) {
          const _arr = []
          for (const item of arr) {
            _arr.unshift(item)
          }
          return _arr
        }
        // this.option.series[0].markLine.data[0].label.formatter = `IC50:${arr[i].IC50.toFixed(3)}μM`
        window.addEventListener('resize', listener)
        // 图标自适应
        // this.option.series[0].color = colorArr[i]
        this.myChart.push(myChart)
        myChart.setOption(this.option)
      })
    },
    getData () {
      getDrugReport(this.experimentId).then(res => {
        console.log('-->', res.data)
        if (res.success) {
          this.info = res.data
          this.getChart(res.data.section2.drugHistogram)
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>
<style lang="scss">
.chartList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .chart {
    width: 100%;
    height: 600px;
  }
}

#app{
  overflow: auto;
}
.table-wrap{
  text-align: center;
  width: 720px;
  margin: 0 auto;
  padding: 50px 0;
  overflow: auto;
  padding-bottom: 100px;
  .btn-wrap{
    position: fixed;
    bottom: 0;
    background: rgba(255,255,255,.9);
    box-shadow: 10px 10px 10px rgba(0,0,0,.1);
    left: 0;
    right: 0;
    border-top: 1px solid #eee;
    padding: 20px 0;
  }
  .table-tips{
    margin-top: 20px;
    text-align: left;
    font-size: 12px;
  }
  .table-box1{
    text-align: justify; font-size: 14px; border-collapse: collapse; width: 100%;
    td{
      width: 12.5%;
      padding: 10px;
    }
  }
  .table-box{
    text-align: justify; font-size: 14px; border-collapse: collapse; width: 100%;
    thead{
      margin-top: 20px;
    }
    td{
      width: 16.6%;
      padding: 10px;
    }
  }
}
</style>
